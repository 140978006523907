import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { getBlockContent } from "../utils/helpers";
import findTalkDateAndTime from "../utils/dateUtil";

// Layout
import Layout from "../components/layout/Layout";
import SpeakerHero from "../components/layout/SpeakerHero";

// Primitives
import Button from "../components/primitives/Button";
import Grid from "../components/primitives/Grid";
import EmojiIcon from "../components/primitives/EmojiIcon";

// Containers
import RenderRichContent from "../components/containers/RenderRichContent";
// Primitives
import EventLink from "../components/primitives/EventLink";

const Speaker = props => {
  const { data } = props;

  const content = data.sanityPerson._rawBio || [];
  const {
    time = "",
    title: talkTitle = "",
    duration = "",
    _rawDescription = {},
  } = data.sanityTalk || {};
  const sanityWorkshop = data.sanityWorkshop || {};
  const { metaInfo } = data.sanityPerson || {};

  const metaStructure = {
    title: metaInfo?.header || data?.sanityPerson?.name,
    someTitle: metaInfo?.someHeader,
    description:
      metaInfo?.description ||
      getBlockContent([data?.sanityPerson?._rawBio]) ||
      data?.sanityPerson?.shoutOut,
    fullSlug: `speaker/${data?.sanityPerson?.slug?.current}`,
    image: metaInfo?.image || data?.sanityPerson?.image,
    imageAlt: metaInfo?.image ? metaInfo?.imageAlt : data?.sanityPerson?.name,
    type: "profile",
  };

  // const { time, title, duration, _rawDescription } = sanityTalk;
  const talkDateAndTime = findTalkDateAndTime(time, duration) || {};
  const { start = "", end = "", day = "" } = talkDateAndTime;

  return (
    <Layout metaInfo={metaStructure}>
      <Helmet>
        <meta
          property="og:profile:first_name"
          content={data?.sanityPerson?.name}
        />
      </Helmet>

      {/* Name + photo of speaker + talk title, company and role */}
      <SpeakerHero
        person={data.sanityPerson}
        title={talkTitle}
        heroType="speaker"
        details={true}
        time={findTalkDateAndTime(time, duration)}
      />

      <Grid className="mb-16">
        <div className="col-span-12 md:col-span-7">
          {/* Where the speaker is from */}
          <p className="font-bold">{data.sanityPerson.shoutOut}</p>

          {/* Speaker bio */}
          <RenderRichContent content={content} />

          {/* SOME buttons. TODO: Needs hooking up! */}
          {data.sanityPerson?.socialMedia?.linkedIn && (
            <div className="flex justify-center mb-2">
              {/* TODO: @Togga @Hanna hook up to Sanity? */}
              <Button
                className="-translate-x-1/3"
                color="green"
                size="xl"
                href={data.sanityPerson?.socialMedia?.linkedIn}
              >
                <EmojiIcon name="someLinkedIn" animated className="mr-1" />
                LinkedIn
              </Button>
            </div>
          )}

          {data.sanityPerson?.socialMedia?.twitter && (
            <div className="flex justify-center">
              <Button
                className="translate-x-1/3"
                color="green"
                size="xl"
                href={data.sanityPerson?.socialMedia?.twitter}
              >
                <EmojiIcon name="someTwitter" animated className="mr-1" />
                Twitter
              </Button>
            </div>
          )}
        </div>
      </Grid>

      {data?.sanityTalk && (
        <React.Fragment key={talkTitle}>
          <Grid>
            <div className="col-span-12">
              {/* Talk talkTitle */}
              <h2 className="block text-3xl uppercase">{talkTitle}</h2>
            </div>

            <div className="col-span-12 md:col-span-7">
              {/* Date & time */}
              <p
                className="
                  font-bold uppercase text-lg
                "
              >
                @ {`${day} ${start} - ${end}`}
              </p>

              {/* Description of talk */}
              <RenderRichContent content={_rawDescription} />
            </div>
          </Grid>
        </React.Fragment>
      )}

      <Grid>
        <div className="col-span-12 md:col-span-7">
          {/* Show workshops if applicable */}
          {data?.sanityWorkshop && <EventLink content={sanityWorkshop} />}

          {/* Centered ticket button */}
          {/* <div className="flex justify-center mb-16">
            <Button color="green" size="lg" href="/tickets" arrows>
              Get your ticket
            </Button>
          </div> */}
        </div>
      </Grid>
    </Layout>
  );
};

export default Speaker;
export const query = graphql`
  query($id: String!) {
    sanityPerson(id: { eq: $id }) {
      id
      name
      shoutOut
      stilling
      company
      language
      socialMedia {
        twitter
        linkedIn
      }
      _rawBio(resolveReferences: { maxDepth: 10 })
      image {
        asset {
          url
        }
      }
      metaInfo {
        description
        header
        someHeader
        image {
          asset {
            url
          }
        }
        imageAlt
      }
    }
    sanityTalk(foredragsholdere: { elemMatch: { id: { eq: $id } } }) {
      title
      _rawDescription(resolveReferences: { maxDepth: 10 })
      time
      duration
      slug {
        current
      }
      _type
    }
    sanityWorkshop(foredragsholdere: { elemMatch: { id: { eq: $id } } }) {
      title
      _rawDescription(resolveReferences: { maxDepth: 10 })
      time
      duration
      slug {
        current
      }
      _type
    }
  }
`;
